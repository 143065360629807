<template>
  <v-card light :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>
        Issue a Chargeback
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <div class="text-center pb-5" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-stepper v-if="!loading" v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :editable="step > 1 && !approval" step="1">
            <v-badge dot color="error" v-if="!valid.step1"> {{ $i18n.translate("Serial Number") }} Lookup </v-badge>
            <span v-else>{{ $i18n.translate("Serial Number") }} Lookup</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="(step > 2 || valid.step1) && !approval && selectedPayouts != null" step="2">
            <v-badge dot color="error" v-if="selectedPayouts != null && !valid.step2">
              Select Payout
            </v-badge>
            <span v-else>Select Payout</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :editable="step > 3 && !approval && selectedPayouts && selectedPayouts.length == 1 && sale != null"
            step="3"
          >
            <v-badge
              dot
              color="error"
              v-if="
                step > 2 && !approval && selectedPayouts && selectedPayouts.length == 1 && sale != null && !valid.step3
              "
            >
              Chargeback Details
            </v-badge>
            <span v-else>Chargeback Details</span>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="(step > 4 || (valid.step1 && valid.step2 && valid.step3)) && !approval" step="4">
            <span>Complete</span>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="vinOrHinLookupForm" @submit.prevent="onSubmit_vinOrHinLookup" v-model="valid.step1">
              <v-container>
                <v-row>
                  <v-col lg="8" md="8" sm="10" xs="12">
                    <h3>Lookup Existing Payment</h3>
                    <p>
                      To begin a Chargeback process, lookup an existing payment by entering a
                      {{ $i18n.translate("Serial Number") }}.
                    </p>
                    <v-text-field
                      maxLength="17"
                      :label="$i18n.translate('Serial Number')"
                      v-model="form_vinOrHinLookup.serialNumber"
                      :rules="[validVin]"
                      class="required"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="payouts && payouts.length == 0">
                  <v-col>
                    <h3>No Payments Found</h3>
                    <p>
                      We were unable to find an existing payment to Chargeback. Please enter another
                      {{ $i18n.translate("Serial Number") }}.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
              <v-btn :loading="loading" color="primary" :disabled="!valid.step1" @click="onSubmit_vinOrHinLookup">
                Search
              </v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-form ref="selectPayoutForm" @submit.prevent="step++" v-if="!loading" v-model="valid.step2">
              <v-container>
                <v-row>
                  <v-col>
                    <template v-if="payouts && payouts.length > 0">
                      <h3>More than one Payout was found</h3>
                      <p>Choose an existing payout to create a Chargeback for.</p>
                    </template>
                    <template v-else>
                      <h3>No Payouts Found</h3>
                      <p>There are no existing payouts to create a Chargeback for.</p>
                    </template>
                    <v-data-table
                      v-if="payouts && payouts.length > 0"
                      :headers="payoutHeaders"
                      :items="payouts"
                      show-select
                      single-select
                      v-model="selectedPayouts"
                      hide-default-footer
                    >
                      <template v-slot:item.payoutKey="{ item }">
                        <router-link :to="{ name: 'payout', params: { id: item.id } }">
                          {{ item.payoutKey }}
                        </router-link>
                      </template>
                      <template v-slot:item.payoutAmount="{ item }">
                        <span v-if="item.payoutAmount">
                          {{ item.payoutAmount | toNumber(2) }}
                          {{ item.currency ? item.currency.name : "" }}
                        </span>
                      </template>

                      <template v-slot:item.promotion.name="{ item }">
                        {{ item.promotion.promotionKey }} <br />
                        {{ item.promotion.name }}
                      </template>

                      <template v-slot:item.participant="{ item }">
                        {{ item.participant ? item.participant.participantKey : "" }} <br />
                        {{ item.participant ? item.participant.fullName : "" }}
                      </template>

                      <template v-slot:item.organization="{ item }">
                        {{ item.organization ? item.organization.organizationKey : "" }} <br />
                        {{ item.organization ? item.organization.name : "" }}
                      </template>

                      <template v-slot:item.product="{ item }">
                        <span v-if="item.claimProduct">
                          {{ item.claimProduct.product.productKey }} <br />

                          {{ item.claimProduct.product.name }}<br />
                        </span>
                        <span v-else-if="item.saleProduct">
                          {{ item.saleProduct.product.productKey }} <br />
                          {{ item.saleProduct.product.name }}<br />
                        </span>
                      </template>

                      <template v-slot:item.productRegistrationDate="{ item }">
                        {{ item.sale.customFieldValue10 | customField(selectedProgram.saleCustomFields[9]) }}
                      </template>

                      <template v-slot:item.bankTransaction="{ item }">
                        <span v-if="item.bankTransaction">
                          {{ item.bankTransaction.bankTransactionKey }}
                          <br />
                        </span>
                        <span v-else> Not paid yet<br /> </span>
                        <span v-if="item.awardVehicleName">
                          {{ item.awardVehicleName }}
                          <br />
                        </span>
                        <span v-if="item.awardVehicleDetermination">
                          {{ item.awardVehicleDetermination.description }}
                          <br />
                        </span>
                      </template>

                      <template v-slot:item.paid="{ item }">
                        {{ item.paid === true ? "Yes" : "No" }}
                      </template>

                      <template v-slot:item.updatedDate="{ item }">
                        {{ item.updatedDate | formatDateFromNow }}
                      </template>
                    </v-data-table>
                    <br /><br />
                    <v-btn text @click="step--">{{ $i18n.translate("Previous") }}</v-btn>
                    <v-btn
                      :loading="loading"
                      class="ml-2"
                      color="primary"
                      :disabled="selectedPayouts.length != 1"
                      @click="onSubmit_selectPayout"
                    >
                      Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-form @submit.prevent="step++" v-model="valid.step2">
              <v-container>
                <v-row>
                  <v-col cols="4">
                    <ApprovalSidebar
                      v-if="sale"
                      :payout="payout"
                      :approvalType="approvalType"
                      :sale="sale"
                      :saleType="saleType"
                      :saleProducts="saleProducts"
                      :saleParticipant="sale.participant"
                      :saleOrganization="sale.organization"
                    />
                  </v-col>
                  <v-col cols="8">
                    <p>
                      Based on the Payout information found by {{ $i18n.translate("Serial Number") }}
                      {{ saleProduct ? saleProduct.serialNumber : "" }}, the Chargeback amount below is prefilled for
                      you.
                    </p>
                    <PromotionSimpleField
                      label="Select a Promotion"
                      :showKey="true"
                      v-model="selectedPromotion"
                      :status="'All'"
                      :rules="rules_payout.promotion"
                      disabled
                    />
                    <v-text-field
                      name="approvalName"
                      label="Name"
                      id="approvalName"
                      v-model="form_payout.approvalName"
                      maxlength="40"
                      :disabled="!isEditing"
                    />
                    <v-text-field
                      name="payoutAmount"
                      label="Payout Amount"
                      id="payoutAmount"
                      type="number"
                      min="0"
                      max="99999"
                      :rules="rules_payout.payoutAmount"
                      v-model="form_payout.payoutAmount"
                      disabled
                      :error-messages="$error.getValidationError(errors, 'payoutAmount')"
                      @input="$error.clearValidationError(errors, 'payoutAmount')"
                    />
                    <AwardVehicleField
                      v-model="selectedAwardVehicle"
                      :label="$i18n.translate('Award Vehicle')"
                      :rules="rules_payout.awardVehicle"
                      disabled
                    ></AwardVehicleField>

                    <OrganizationField
                      v-if="selectedPromotion && promotionPaysToOrganization"
                      v-model="selectedOrganization"
                      :rules="rules_payout.organization"
                      class="required"
                      showKey
                      showParticipantType
                      :label="'Payout Assignment for ' + $i18n.translate('Organization')"
                    ></OrganizationField>

                    <ParticipantField
                      v-else-if="selectedPromotion"
                      v-model="selectedParticipant"
                      :rules="rules_payout.participant"
                      class="required"
                      showKey
                      :label="'Payout Assignment for ' + $i18n.translate('Participant')"
                    ></ParticipantField>

                    <v-textarea name="comment" label="Comment" id="comment" v-model="form_payout.comment" />
                    <UploadField
                      label="Supporting Documentation"
                      :limitNumberFile="1"
                      v-model="selectedUpload"
                    ></UploadField>
                    <v-btn text @click="step--">{{ $i18n.translate("Previous") }}</v-btn>
                    <v-btn
                      :loading="loading"
                      class="ml-2"
                      color="primary"
                      :disabled="!valid.step2"
                      @click="onSubmit_payout"
                    >
                      Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-container>
              <v-row>
                <v-col cols="4">
                  <ApprovalSidebar
                    v-if="sale"
                    :approval="approval"
                    :payout="payout"
                    :approvalType="approvalType"
                    :sale="sale"
                    :saleType="saleType"
                    :saleProducts="saleProducts"
                    :saleParticipant="sale.participant"
                    :saleOrganization="sale.organization"
                  />
                </v-col>
                <v-col cols="8">
                  <h2>Success!</h2>
                  <p class="mt-3 mb-10">
                    A Chargeback <b>payout</b> and <b>approval</b> has been created. Once this Chargeback payout has
                    been approved, it will be paid.
                  </p>
                  <v-btn :loading="loading" color="primary" @click="onStartOver">
                    Issue another Chargeback
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import AwardVehicleField from "../../gapp-components/components/fields/AwardVehicleField.vue";
import OrganizationField from "../../gapp-components/components/fields/OrganizationField.vue";
import ParticipantField from "../../gapp-components/components/fields/ParticipantField.vue";
import PromotionSimpleField from "../../gapp-components/components/fields/PromotionSimpleField.vue";
import UploadField from "../../gapp-components/components/fields/UploadField.vue";
import ApprovalSidebar from "../../gapp-components/components/views/promotions/ApprovalSidebar.vue";

export default {
  components: {
    AwardVehicleField,
    ParticipantField,
    UploadField,
    ApiError,
    ApprovalSidebar,
    PromotionSimpleField,
    OrganizationField
  },
  name: "Chargeback",
  metaInfo: {
    title: "Chargeback"
  },
  data: () => ({
    step: "1",
    valid: {
      step1: false,
      step2: false,
      step3: false
    },
    loading: false,
    isEditing: true,
    errors: {},
    saleType: null,
    sale: null,
    saleProducts: [],
    saleProduct: null,
    approvalType: null,
    approval: null,
    payout: null,

    selectedPayouts: [],
    payoutHeaders: [],
    payouts: null,
    defaultPayouts: null,

    selectedPromotion: null,
    selectedParticipant: null,
    selectedOrganization: null,
    selectedUpload: {
      existing: [],
      deleted: []
    },
    selectedAwardVehicle: null,
    availableAwardVehicles: [],

    form_vinOrHinLookup: {
      serialNumber: ""
    },
    form_payout: {
      payoutAmount: "0"
    },
    rules_vinOrHinLookup: {
      serialNumber: [
        v =>
          (!!v && (v.trim().length == 12 || v.trim().length == 15 || v.trim().length == 17)) ||
          "A valid VIN or HIN is required."
      ]
    },
    rules_payout: {
      awardVehicle: [v => !!v || "An award vehicle is required."],
      promotion: [v => (!!v && v.id > 0) || "A promotion is required."],
      payoutAmount: [v => !!v || "A payout amount is required."],
      participant: [v => (!!v && v.id > 0) || "A payout assignment is required."],
      organization: [v => (!!v && v.id > 0) || "A payout assignment is required."]
    }
  }),
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram", "selectedLocale"]),
    validAll() {
      return this.valid.step1 && this.valid.step2 && this.valid.step3;
    },
    promotionPaysToOrganization() {
      if (this.selectedPromotion && this.selectedPromotion.payoutStrategyType) {
        switch (this.selectedPromotion.payoutStrategyType.name) {
          case "PAY_TO_ORGANIZATION":
          case "PAY_TO_ORGANIZATION_SPECIFIC":
          case "PAY_TO_ORGANIZATION_PARENT":
          case "PAY_TO_PARTICIPANT_ORGANIZATION":
          case "PAY_TO_PARTICIPANT_ORGANIZATION_PARENT":
            return true;
          default:
            return false;
        }
      }
      return false;
    }
  },
  mounted() {
    this.payoutHeaders = [
      {
        value: "promotion.name",
        text: this.$i18n.translate("Promotion"),
        sortable: true
      },
      {
        value: "participant",
        text: this.$i18n.translate("Participant"),
        sortable: true
      },
      {
        value: "organization",
        text: this.$i18n.translate("Organization"),
        sortable: true
      },
      {
        value: "product",
        text: this.$i18n.translate("Product"),
        sortable: false
      },
      {
        value: "productRegistrationDate",
        text: this.$i18n.translate("Product Registration Date"),
        sortable: true
      },
      {
        value: "payoutAmount",
        text: this.$i18n.translate("Payout"),
        align: "right",
        sortable: true
      },
      {
        value: "bankTransaction",
        text: this.$i18n.translate("Payment"),
        sortable: false
      }
    ];
    this.fetchApprovalType("KMC_CHARGEBACK").catch(error => {
      this.errors = this.$api.getErrorsFromResponse(error);
    });
  },
  methods: {
    validVin(v) {
      if (!this.approvalType || !this.approvalType.id) {
        return "Missing approval type configuration for KMC_CHARGEBACK.";
      } else if (v && (v.trim().length == 12 || v.trim().length == 15 || v.trim().length == 17)) {
        return true;
      } else {
        return "A valid VIN or HIN is required.";
      }
    },
    onStartOver() {
      this.step = 1;
      this.selectedPayouts = [];
      this.payout = null;
      this.form_vinOrHinLookup = {
        serialNumber: ""
      };
    },
    fetchApprovalType(approvalTypeKey) {
      return this.$api
        .post("/api/approvalTypes/search?size=1", { approvalTypeKey: approvalTypeKey })
        .then(({ data }) => {
          if (data && data.content && data.content.length == 1) {
            this.approvalType = data.content[0];
          } else {
            return Promise.reject("Unable to find approval type by key: " + approvalTypeKey);
          }
        });
    },
    onSubmit_vinOrHinLookup() {
      if (!this.valid.step1) {
        return;
      }
      this.form_vinOrHinLookup.serialNumber = this.form_vinOrHinLookup.serialNumber.trim();
      this.errors = {};
      this.loading = true;
      return this.$api
        .post("/api/sales/search", {
          serialNumber: this.form_vinOrHinLookup.serialNumber
        })
        .then(({ data }) => {
          let sales = data.content.map(sale => {
            return { id: sale.id };
          });
          return this.$api
            .post("/api/payouts/search?size=100", {
              sales: sales,
              payoutAmountMin: 0
            })
            .then(async ({ data }) => {
              this.defaultPayouts = data.content.filter(p => p.paid);
              const payoutsWithChargebacks = await Promise.all(
                data.content.map(async payout => ({
                  payout,
                  hasChargeback: await this.determineIfExistingPayoutHasChargeback(payout)
                }))
              );
              this.payouts = payoutsWithChargebacks
                ?.filter(({ payout, hasChargeback }) => payout.paid && !hasChargeback)
                ?.map(({ payout }) => payout);

              if (this.payouts.length == 1) {
                this.selectedPayouts = [this.payouts[0]];
                this.payout = this.selectedPayouts[0];
                this.step++;
                return this.populatePayout(this.payout).then(() => {
                  this.step++;
                });
              } else if (this.payouts.length > 1) {
                this.step++;
              }

              return Promise.resolve();
            });
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit_selectPayout() {
      this.errors = {};
      this.payout = this.selectedPayouts[0];
      this.populatePayout(this.payout)
        .then(() => {
          this.step++;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    populatePayout(payout) {
      this.sale = payout.sale;
      this.saleType = this.sale.saleType;
      this.saleProduct = payout.saleProduct;
      this.saleProducts = [payout.saleProduct];
      this.selectedPromotion = payout.promotion;
      this.selectedOrganization = payout.organization;
      this.selectedParticipant = payout.participant;
      this.selectedAwardVehicle = payout.bankTransaction.awardVehicle;
      this.form_payout.payoutAmount = payout.payoutAmount * -1;
      this.form_payout.approvalName = "Chargeback on " + this.saleProduct.serialNumber;
      return this.determineIfExistingPayoutHasChargeback(payout);
    },
    fetchAvailableAwardVehicles() {
      this.selectedAwardVehicle = null;
      if (this.selectedPromotion) {
        return this.$api
          .get("/api/promotionTypes/" + this.selectedPromotion.promotionType.id + "/availableAwardVehicles")
          .then(({ data }) => {
            this.availableAwardVehicles = data._embedded.awardVehicles;
          });
      } else {
        return Promise.resolve();
      }
    },
    determineIfExistingPayoutHasChargeback(payout) {
      return this.$api
        .post("/api/payouts/search?size=1", { duplicationKey: this.chargebackDuplicationKeyFromPayout(payout) })
        .then(({ data }) => {
          if (data && data.content && data.content.length > 0) {
            // Step 1: Create a Set of cleaned duplicationKeys
            const duplicationKeySet = new Set(
              data.content.map(contentItem => contentItem.duplicationKey.replace("CHARGEBACK_", ""))
            );

            // Step 2: Filter payouts in one pass using the Set for fast lookups
            this.defaultPayouts = this.defaultPayouts?.filter(
              payoutItem => !duplicationKeySet.has(payoutItem.payoutKey)
            );
            const finalResult =
              this.defaultPayouts.length > 0
                ? this.defaultPayouts
                : Promise.reject("An existing Chargeback has already been created for this payout");
            return finalResult;
          } else {
            return Promise.resolve();
          }
        });
    },
    onSubmit_payout() {
      this.errors = {};
      this.loading = true;

      return this.createPayout()
        .then(() => {
          this.step++;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    chargebackDuplicationKeyFromPayout(payout) {
      return "CHARGEBACK_" + payout.payoutKey;
    },
    createPayout() {
      let payout = {
        duplicationKey: this.chargebackDuplicationKeyFromPayout(this.payout),
        awardVehicleDetermination: { name: "DETERMINED_BY_MANUAL" },
        program: { id: this.selectedProgram.id },
        awardVehicle: { id: this.selectedAwardVehicle.id },
        currency: { name: "USD" },
        promotion: { id: this.selectedPromotion.id },
        payoutStrategyType: { name: this.selectedPromotion?.payoutStrategyType?.name },
        sale: { id: this.sale.id },
        saleProduct: { id: this.saleProduct.id },
        payoutAmount: parseFloat(this.form_payout.payoutAmount),
        payoutType: { name: "MONEY" },
        payoutMessage: this.form_payout.approvalName
      };
      if (this.promotionPaysToOrganization && this.selectedOrganization) {
        payout.organization = { id: this.selectedOrganization.id };
      } else if (this.selectedParticipant) {
        payout.participant = { id: this.selectedParticipant.id };
      } else {
        return Promise.reject("Assignment of incentive is missing");
      }

      return this.$api.post("/api/payouts/create", payout).then(({ data }) => {
        this.payout = data;
        return this.createApproval(data);
      });
    },
    saveApprovalUpload(approval) {
      let postForm = { id: approval.id };
      if (this.selectedUpload.existing) {
        postForm.uploads = this.selectedUpload.existing.map(upload => this.$api.getSelfUrl("uploads", upload));
        return this.$api.patch("/api/approvals/" + approval.id, postForm);
      } else {
        return Promise.resolve();
      }
    },
    createApproval(payout) {
      let approval = {
        payout: { id: payout.id },
        name: this.form_payout.approvalName,
        approvalType: { id: this.approvalType.id }
      };
      return this.$api.post("/api/approvals/create", approval).then(({ data }) => {
        this.approval = data;
        return this.createApprovalNote(data).then(() => {
          return this.saveApprovalUpload(this.approval);
        });
      });
    },
    createApprovalNote(approval) {
      if (this.form_payout.comment && this.form_payout.comment.trim().length > 0) {
        let approvalNote = {
          approval: { id: approval.id },
          note: this.form_payout.comment
        };
        return this.$api.post("/api/approvalNotes", approvalNote);
      } else {
        return Promise.resolve();
      }
    }
  }
};
</script>
